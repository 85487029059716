* {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background-color: #fff;
  color: #323957;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
}
.header {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #d53a3d;
}
.header .logo {
  margin-left: 20px;
  top: 12px;
  width: 300px;
  height: 80px;
  background-image: url("images/logoleft.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.header .logo,
.header .title,
.header .subtitle {
  position: absolute;
  left: 0px;
  right: 0px;
  font-size: 40px;
  padding: 0px 20px;
  vertical-align: bottom;
  font-weight: 700;
}
.header .title,
.header .subtitle {
  margin-top: 48px;
}
.header .title {
  text-align: center;
}
.header .subtitle {
  text-align: right;
  color: #d53a3d;
}
.scoreboard {
  position: absolute;
  top: 100px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #ff0;
}
.form-control {
  font-size: 0.8rem;
  height: 1.8rem;
}
.react-grid-Main {
  height: 100%;
}
.react-grid-Container {
  height: 100%;
}
.react-grid-Grid {
  min-height: 100% !important;
}
.react-grid-Canvas {
  height: 100% !important;
}
.table {
  padding: 20px;
  font-size: 16px;
}
.table .tableheader,
.table .player {
  clear: both;
  border-bottom: 1px solid #e2b75c;
  height: 24px;
  margin-top: 2px;
}
.table .tableheader {
  font-weight: bold;
}
.table .bibnumber,
.table .rank,
.table .category,
.table .categoryRank,
.table .name,
.table .routesDone,
.table .game,
.table .swim,
.table .t1,
.table .bike,
.table .t2,
.table .run,
.table .total {
  white-space: nowrap;
  height: 100%;
}
.table .bibnumber,
.table .rank,
.table .name,
.table .category,
.table .categoryRank,
.table .game {
  float: left;
}
.table .swim,
.table .t1,
.table .bike,
.table .t2,
.table .run,
.table .total {
  float: right;
}
.table .bibnumber {
  width: 46px;
  text-align: right;
  margin-right: 14px;
}
.table .rank {
  width: 50px;
}
.table .name {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .game {
  width: 87px;
}
.table .category {
  width: 86px;
}
.table .categoryRank {
  width: 79px;
}
.table .swim,
.table .t1,
.table .bike,
.table .t2,
.table .run,
.table .total {
  width: 85px;
}
